/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 576px) {
  .carousel {
    height: 150px;
  }

  .imgHeight {
    height: 100px;
    width: 100px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 576px) {
  .carousel {
    height: 250px;
  }
  .imgHeight {
    height: 125px;
    width: 125px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .carousel {
    height: 300px;
  }
  .imgHeight {
    height: 150px;
    width: 150px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .carousel {
    height: 350px;
  }
  .imgHeight {
    height: 175px;
    width: 175px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .carousel {
    height: 450px;
  }
  .imgHeight {
    height: 200px;
    width: 200px;
  }
}
@media only screen and (min-width: 1400px) {
  .carousel {
    height: 600px;
  }
  .imgHeight {
    height: 250px;
    width: 250px;
  }
}
